import {
  ReservationsStatsWidget,
  ReservationsStatsWidgetRequest,
} from '@app/models';
import { createAction, props } from '@ngrx/store';

export const loadStatsWidgetRequest = createAction(
  '[Reservations Stats] Load Stats Widget Request',
  props<{ data: ReservationsStatsWidgetRequest }>(),
);

export const loadStatsWidgetSuccess = createAction(
  '[Reservations Stats] Load Stats Widget Success',
  props<{ data: ReservationsStatsWidget }>(),
);

export const loadStatsWidgetFailure = createAction(
  '[Reservations Stats] Load Stats Widget Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Reservations Stats] Reset State');
