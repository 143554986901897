import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationsStatsStoreEffects } from './effects';
import { reservationsStatsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('reservationsStats', reservationsStatsReducer),
    EffectsModule.forFeature([ReservationsStatsStoreEffects]),
  ],
})
export class ReservationsStatsStoreModule {}
