import { Action, createReducer, on } from '@ngrx/store';
import moment from 'moment';

import { floatRound } from '../../helpers/float-round';
import { ReservationsStatsWidgetData, StatsHeaderWidget } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

const KEYS = ['today', 'last_week', 'current_month', 'current_year'];

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadStatsWidgetRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadStatsWidgetSuccess, (state, { data }) => {
    const widgetdata: StatsHeaderWidget[] = KEYS.map((keyPeriod) => {
      return createWidgetDataStats(data[keyPeriod], keyPeriod);
    });
    return {
      ...state,
      data: widgetdata,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadStatsWidgetFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.reseState, () => fromState.initialState),
);

export function reservationsStatsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function createWidgetDataStats(
  data: ReservationsStatsWidgetData,
  key: string,
): StatsHeaderWidget {
  const year = moment().format('YYYY');
  const title = {
    key: `reservations_stats.${key}`,
  };
  const prefixIcon =
    data.total.diff_percent >= 0
      ? 'fas fa-arrow-alt-up color--green'
      : 'fas fa-arrow-alt-down color--red';

  let subtitle: any = {
    key: `year_compare_no_value`,
    params: {
      year: +year - 1,
    },
  };
  if (data.total.previous > 0) {
    subtitle = {
      key: `year_compare`,
      params: {
        year: +year - 1,
        percent_value: floatRound(data.total.diff_percent),
      },
      prefixIcon,
    };
  }

  return {
    value: data.total.current,
    count: data.reservations.current,
    title,
    subtitle,
    valueType: 'currency',
  };
}
